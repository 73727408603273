import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/campus/:campus_id', name: 'Home', component: () => import('../views/Campus.vue') },
  { path: '/campus/:campus_id/special/:special_id', name: 'Special', component: () => import('../components/Special.vue') },
  { path: '/campus/:campus_id/special/:special_id/classify/:classify_id', name: 'Classify', component: () => import('../components/Classify.vue') },
  { path: '/campus/:campus_id/special/:special_id/classify/:classify_id/course/:course_id', name: 'Course', component: () => import('../components/Course.vue') }
]

const router = new VueRouter({
  routes
})

export default router
