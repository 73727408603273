<template>
  <div class="login-page">
    <div class="login-panle">
      <el-form :model="loginForm" :rules="loginRules" ref="loginRef" class="login-form" :hide-required-asterisk="true">
        <el-form-item label="手机号" prop="username">
          <el-input v-model="loginForm.username" clearable  prefix-icon="el-icon-user">
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="loginForm.password" clearable show-password prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 40px; text-align: right;">
          <el-button type="primary" @click="submitForm">登 录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <div class="support">{{baseInfo.support}}</div>
      <div class="record" @click="toRecord">{{baseInfo.copyright}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      baseInfo: {
        support: '',
        copyright: ''
      },
      loginForm: {
        campus_id: this.$route.params.campus_id,
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 5, max: 11, message: '长度在 5 到 11 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // 获取备案号和技术支持板块
    this.getBaseInfo()
    if (window.localStorage.getItem('account')) {
      this.loginForm.username = window.localStorage.getItem('account')
    }
    if (window.localStorage.getItem('password')) {
      this.loginForm.password = window.localStorage.getItem('password')
    }
  },
  methods: {
    async getBaseInfo () {
      const { data: res } = await this.$http.get('/base-info')
      if (res.code === 200) {
        this.baseInfo.support = res.data.support
        this.baseInfo.copyright = res.data.copyright
      } else {
        this.$message.error(res.msg)
      }
    },
    submitForm () {
      this.$refs.loginRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/login', this.loginForm)
          if (res.code === 200) {
            // 更新vuex数据
            this.$store.commit('updataUserInfo', res.data)
            this.$message.success(res.msg)
            window.localStorage.setItem('account', this.loginForm.username)
            window.localStorage.setItem('password', this.loginForm.password)
            await this.$router.push('/campus/' + res.data.campus)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toRecord () {
      window.open('https://beian.miit.gov.cn/', '_blank')
    }
  }
}
</script>

<style scoped>
  .login-page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/img/bg.jpg");
    background-size: cover;
    position: relative;
  }
  .login-panle{
    width: 400px;
    background-color: rgba(255,255,255,.8);
    padding: 30px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 6px;
  }
  .footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
  }
  .support{}
  .record{
    cursor: pointer;
    margin-top: 4px;
  }
</style>
