<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created () {
    this.$nextTick(() => {
      // 禁用右键
      // eslint-disable-next-line no-new-func
      document.oncontextmenu = new Function('event.returnValue=false')
      // 禁用选择
      // eslint-disable-next-line no-new-func
      document.onselectstart = new Function('event.returnValue=false')
      // 禁止f12
      // eslint-disable-next-line no-new-func
      document.οnkeydοwn = new Function('event.returnValue=false')
    })

    // 上面的禁止f12那段代码没有生效，但是加了下面这段就能生效。
    document.onkeydown = function (e) {
      if (e && e.keyCode === 123) {
        e.returnValue = false
        // e.keyCode = 0   //去掉也可以的，倘若要写，则需要setter 以及 getter配合使用，不配合，会报错
        return false
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*{
  box-sizing: border-box;
}
body,html{
  margin: 0;
  padding: 0;
  -moz-user-select:none;/*火狐*/
  -webkit-user-select:none;/*webkit浏览器*/
  -ms-user-select:none;/*IE10*/
  -khtml-user-select:none;/*早期浏览器*/
  user-select:none;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
  pointer-events: auto!important;
}
</style>
