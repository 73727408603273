import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      m_id: '',
      username: ''
    },
    token: '',
    schoolInfo: {
      m_id: '',
      title: '',
      logo: '',
      background: '',
      watermark: ''
    }
  },
  mutations: {
    updataUserInfo (state, userinfo) {
      state.userInfo.m_id = userinfo.m_id
      state.userInfo.username = userinfo.username
    },
    clearUserInfo (state) {
      state.userInfo.m_id = ''
      state.userInfo.username = ''
    },
    updateToken (state, token) {
      state.token = token
    },
    clearToken (state, token) {
      state.token = ''
    },
    // 更新校区信息
    updateSchoolInfo (state, schoolInfo) {
      state.schoolInfo.m_id = schoolInfo.m_id
      state.schoolInfo.title = schoolInfo.title
      state.schoolInfo.logo = schoolInfo.logo
      state.schoolInfo.background = schoolInfo.background
      state.schoolInfo.watermark = schoolInfo.watermark
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      reducer (val) {
        return {
          // 只储存state中的assessmentData
          userInfo: val.userInfo,
          token: val.token,
          schoolInfo: val.schoolInfo
        }
      }
    })
  ]
})
